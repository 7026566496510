import { returnsApi, salesApi, systemApi } from '@/addons/axios'
import { convertPYDateFormatToJS } from '@/addons/date'
import { LanguagesPosweb, LocalStorageKeys, TipiVendita } from '@/addons/enums'
import checkDevice, { DeviceType } from '@/addons/mobile'
import {
  GetCashesData,
  GetDiscountsData,
  GetItemsClassesAttributes,
  GetItemsFabricsAttributes,
  GetStoreConfigDataAttributes,
  GetStoreConfigDataAttributesConsumerInteressi,
  GetStoreConfigDataAttributesConsumerProfessioni,
  GetStoreConfigDataAttributesStoreData,
  GetStoreConfigDataAttributesTipiVendita,
  GetStoreConfigDataAttributesTipiVenditaTessuto,
  GetSystemConfigDataAttributes,
  ReturnData,
} from '@/api'
import router, { isLoginPage } from '@/router'
import store, { RootState } from '@/store'
import _ from 'lodash'
import { ActionContext } from 'vuex'
import { AuthGetters } from '@/store/auth'
import { LoyaltyActions } from '@/modules/loyalty/store/index'
import { isConfigTruthy } from '@/addons/functions/configs'
import { CdnManifest } from '@/interfaces/cdnManifest'
type ConfigActionContext = ActionContext<ConfigsState, RootState>

export interface Carousel {
  online: string[]
  offline: string[]
}

export interface ConfigsState {
  setup: GetSystemConfigDataAttributes | null
  currentStore?: GetStoreConfigDataAttributes
  discounts: GetDiscountsData[]
  productClasses: GetItemsClassesAttributes[]
  device?: DeviceType
  fabrics?: GetItemsFabricsAttributes[]
  stores?: ReturnData[]
  selectedCashUrl?: string
  storeCdnManifest?: CdnManifest
}

enum Actions {
  FETCH_STORE_SETUP = 'fetchStoreSetup',
  FETCH_STORE_CONFIGS = 'fetchCurrentStore',
  UPDATE_DISCOUNTS = 'updateDiscounts',
  UPDATE_PRODUCT_CLASSES = 'updateClasses',
  WATCH_DEVICE_CHANGE = 'watchDeviceChange',
  SET_FABRICS = 'setFabrics',
  RESET_STATE = 'reset-state',
  SET_STORE = 'setStore',
  GET_STORE_CDN_MANIFEST = 'getStoreCdnManifest',
}

export const ConfigActions = {
  FETCH_STORE_SETUP: `configs/${Actions.FETCH_STORE_SETUP}`,
  FETCH_STORE_CONFIGS: `configs/${Actions.FETCH_STORE_CONFIGS}`,
  UPDATE_DISCOUNTS: `configs/${Actions.UPDATE_DISCOUNTS}`,
  UPDATE_PRODUCT_CLASSES: `configs/${Actions.UPDATE_PRODUCT_CLASSES}`,
  SET_FABRICS: `configs/${Actions.SET_FABRICS}`,
  SET_STORE: `configs/${Actions.SET_STORE}`,
  RESET_STATE: `configs/${Actions.RESET_STATE}`,
  GET_STORE_CDN_MANIFEST: `configs/${Actions.GET_STORE_CDN_MANIFEST}`,
}

export enum ConfigMutations {
  SET_STORE_SETUP = 'setStoreSetup',
  SET_STORE_CONFIGS = 'setStoreConfigs',
  DEVICE = 'setDevice',
  DISCOUNTS = 'setDiscounts',
  PRODUCT_CLASSES = 'setProductClasses',
  FABRICS = 'setFabrics',
  SET_SELECTED_CASH = 'setSelectedCash',
  SET_SELECTED_CASH_URL = 'setSelectedCashUrl',
  UPDATE_STORE = 'updateStore',
  RESET_STATE = 'reset-state',
  SAVE_STORE_CDN_MANIFEST = 'saveStoreCdnManifest',
}

enum Getters {
  GET_STORE_SETUP = 'getStoreSetup',
  GET_STORE_CONFIGS = 'getStoreConfigs',
  FABRICS = 'getFabrics',
  CLASSES = 'getClasses',
  TIPI_VENDITA = 'tipi-vendita',
  TIPI_VENDITA_TESSUTO = 'tipi-vendita-tessuto',
  LISTINO = 'listino',
  DEVICE = 'device',
  IS_MOBILE = 'is-device',
  LISTINO_FILTRATO = 'listino-filtrato',
  MAX_DISCOUNT = 'max-discount',
  DEPLOY_ENV = 'deploy-env',
  IS_LIVE_ENVIRONMENT = 'is-live-env',
  USE_LOGIN_PORTAL = 'use-login-portal',
  GET_PORTAL_URL = 'get-portal-url',
  SELECTED_CASH = 'selected-cash',
  GET_SELECTED_CASH_URL = 'get-selected-cash-url',
  STORE_CODE = 'store-code',
  GET_MAX_PAYABLE_CASH_AMOUNT = 'get-sale-max-payable-cash-amount',
  GET_MAX_PAYABLE_CASH_AMOUNT_EXTRA_COUNTRY = 'get-sale-max-payable-cash-amount-extra-country',
  GET_MAX_PAYABLE_CASH_AMOUNT_EXTRA_AML = 'get-sale-max-payable-cash-amount-extra-aml',
  CLASS_DESCRIPTION = 'get-class-description',
  INTEREST = 'interest',
  PROFESSION = 'profession',
  GET_STORES = 'getStore',
  GET_CURRENT_STORE = 'get-current-store',
  GET_STORE_DETAILS = 'get-store-details',
  GET_DISCOUNTS = 'get-discounts',
  GET_COUNTRY_CODE = 'get-country-code',
  GET_CURRENCY_SIGN = 'getCurrencySign',
  GET_GIFTCARD_MIN_AMOUNT = 'getGiftcardMinAmount',
  GET_GIFTCARD_MAX_AMOUNT = 'getGiftcardMaxAmount',
  GET_CUSTOM_DATE = 'getCustomDate',
  GET_DATE_FORMAT = 'getDateFormat',
  GET_LOCALE = 'getLocale',
}

export const ConfigGetters = {
  GET_STORE_SETUP: `configs/${Getters.GET_STORE_SETUP}`,
  GET_STORE_CONFIGS: `configs/${Getters.GET_STORE_CONFIGS}`,
  FABRICS: `configs/${Getters.FABRICS}`,
  CLASSES: `configs/${Getters.CLASSES}`,
  TIPI_VENDITA: `configs/${Getters.TIPI_VENDITA}`,
  TIPI_VENDITA_TESSUTO: `configs/${Getters.TIPI_VENDITA_TESSUTO}`,
  LISTINO: `configs/${Getters.LISTINO}`,
  DEVICE: `configs/${Getters.DEVICE}`,
  IS_MOBILE: `configs/${Getters.IS_MOBILE}`,
  LISTINO_FILTRATO: `configs/${Getters.LISTINO_FILTRATO}`,
  MAX_DISCOUNT: `configs/${Getters.MAX_DISCOUNT}`,
  DEPLOY_ENV: `configs/${Getters.DEPLOY_ENV}`,
  IS_LIVE_ENVIRONMENT: `configs/${Getters.IS_LIVE_ENVIRONMENT}`,
  USE_LOGIN_PORTAL: `configs/${Getters.USE_LOGIN_PORTAL}`,
  GET_PORTAL_URL: `configs/${Getters.GET_PORTAL_URL}`,
  SELECTED_CASH: `configs/${Getters.SELECTED_CASH}`,
  GET_SELECTED_CASH_URL: `configs/${Getters.GET_SELECTED_CASH_URL}`,
  STORE_CODE: `configs/${Getters.STORE_CODE}`,
  GET_MAX_PAYABLE_CASH_AMOUNT: `configs/${Getters.GET_MAX_PAYABLE_CASH_AMOUNT}`,
  GET_MAX_PAYABLE_CASH_AMOUNT_EXTRA_COUNTRY: `configs/${Getters.GET_MAX_PAYABLE_CASH_AMOUNT_EXTRA_COUNTRY}`,
  GET_MAX_PAYABLE_CASH_AMOUNT_EXTRA_AML: `configs/${Getters.GET_MAX_PAYABLE_CASH_AMOUNT_EXTRA_AML}`,
  CLASS_DESCRIPTION: `configs/${Getters.CLASS_DESCRIPTION}`,
  INTEREST: `configs/${Getters.INTEREST}`,
  PROFESSION: `configs/${Getters.PROFESSION}`,
  GET_STORES: `configs/${Getters.GET_STORES}`,
  GET_CURRENT_STORE: `configs/${Getters.GET_CURRENT_STORE}`,
  GET_STORE_DETAILS: `configs/${Getters.GET_STORE_DETAILS}`,
  GET_DISCOUNTS: `configs/${Getters.GET_DISCOUNTS}`,
  GET_COUNTRY_CODE: `configs/${Getters.GET_COUNTRY_CODE}`,
  GET_CURRENCY_SIGN: `configs/${Getters.GET_CURRENCY_SIGN}`,
  GET_GIFTCARD_MIN_AMOUNT: `configs/${Getters.GET_GIFTCARD_MIN_AMOUNT}`,
  GET_GIFTCARD_MAX_AMOUNT: `configs/${Getters.GET_GIFTCARD_MAX_AMOUNT}`,
  GET_CUSTOM_DATE: `configs/${Getters.GET_CUSTOM_DATE}`,
  GET_DATE_FORMAT: `configs/${Getters.GET_DATE_FORMAT}`,
  GET_LOCALE: `configs/${Getters.GET_LOCALE}`,
}

const initState = (): ConfigsState => ({
  setup: null,
  currentStore: undefined,
  device: 'pc' as DeviceType,
  selectedCashUrl: undefined,
  discounts: [],
  productClasses: [],
  fabrics: [],
  storeCdnManifest: {},
})

function onWindowResize() {
  _.throttle((): void => {
    store.commit(ConfigMutations.DEVICE, checkDevice())
  }, 200)
}

export default {
  namespaced: true,
  state: initState,
  actions: {
    async [Actions.FETCH_STORE_SETUP]({
      commit,
      dispatch,
      rootState,
    }: ConfigActionContext): Promise<void> {
      // Start watching for screen size changes
      dispatch(Actions.WATCH_DEVICE_CHANGE)

      const setupConfigApiResponse =
        await systemApi.apiV1PoswebGlobalConfigGet()

      const globalConfig = setupConfigApiResponse.data?.data?.attributes || {}

      commit(ConfigMutations.SET_STORE_SETUP, globalConfig)
      if (rootState.generic.setLanguageToDefault) {
        const locale =
          LanguagesPosweb[
            globalConfig?.gui_language as keyof typeof LanguagesPosweb
          ]
        store.dispatch('switchLanguage', {
          language: locale,
          localesModules: router.currentRoute.value.meta
            .localesModules as string[],
          json: isLoginPage(router.currentRoute.value),
        })
      }
    },
    async [Actions.FETCH_STORE_CONFIGS]({
      commit,
    }: ConfigActionContext): Promise<void> {
      const r = await systemApi.apiV1PoswebConfigGet('FRONTEND')
      commit(ConfigMutations.SET_STORE_CONFIGS, r.data?.data?.attributes)
      if (isConfigTruthy('CARD_ENABLED')) {
        await store.dispatch(LoyaltyActions.SET_PROMOTION_TYPE)
      }
    },
    [Actions.RESET_STATE]({ commit }: ConfigActionContext): void {
      localStorage.removeItem(LocalStorageKeys.SELECTED_CASH)
      window.removeEventListener('resize', onWindowResize)
      commit(ConfigMutations.RESET_STATE)
    },
    async [Actions.SET_STORE](
      { commit }: ConfigActionContext,
      serachValue: string
    ): Promise<void> {
      const stores = await returnsApi.apiV1StoresGet(serachValue)
      commit(ConfigMutations.UPDATE_STORE, stores.data)
    },
    async [Actions.UPDATE_DISCOUNTS]({
      commit,
    }: ConfigActionContext): Promise<void> {
      const discountsApiResponse = await salesApi.apiV1PoswebDiscountsGet()
      commit(ConfigMutations.DISCOUNTS, discountsApiResponse.data?.data)
    },
    async [Actions.UPDATE_PRODUCT_CLASSES]({
      commit,
    }: ConfigActionContext): Promise<void> {
      const classesApiResponse = await salesApi.apiV1PoswebClassesGet()
      const attributes = classesApiResponse.data?.data?.map((c) => c.attributes)
      commit(ConfigMutations.PRODUCT_CLASSES, attributes)
    },
    [Actions.WATCH_DEVICE_CHANGE]({ commit }: ConfigActionContext): void {
      commit(ConfigMutations.DEVICE, checkDevice())
      window.addEventListener('resize', onWindowResize)
    },
    async [Actions.SET_FABRICS]({
      commit,
    }: ConfigActionContext): Promise<void> {
      const fabrics = await salesApi.apiV1PoswebFabricsGet()
      commit(
        ConfigMutations.FABRICS,
        fabrics.data?.data?.map((r) => r.attributes)
      )
    },
    [Actions.GET_STORE_CDN_MANIFEST](
      { commit }: ConfigActionContext,
      payload: CdnManifest
    ): void {
      commit(ConfigMutations.SAVE_STORE_CDN_MANIFEST, payload || {})
    },
  },
  mutations: {
    [ConfigMutations.SET_STORE_SETUP](
      state: ConfigsState,
      configs: GetSystemConfigDataAttributes
    ) {
      state.setup = { ...configs }
    },
    [ConfigMutations.UPDATE_STORE](state: ConfigsState, stores: ReturnData[]) {
      state.stores = stores
    },

    [ConfigMutations.SET_SELECTED_CASH](state: ConfigsState, cashId: string) {
      const cashes: GetCashesData[] = store.getters[AuthGetters.CASHES] || []
      const cash = cashes.find((cash) => cash.attributes?.cod_cassa === cashId)

      if (cash) {
        state.setup = {
          ...state.setup,
          cash_id: cashId,
          cash_role: cash.attributes?.cash_role,
          feature_set: cash.attributes?.feature_set,
        }

        localStorage.setItem(LocalStorageKeys.SELECTED_CASH, cashId)
      }
    },

    [ConfigMutations.SET_SELECTED_CASH_URL](
      state: ConfigsState,
      cashUrl: string
    ) {
      state.selectedCashUrl = cashUrl
    },

    [ConfigMutations.SET_STORE_CONFIGS](
      state: ConfigsState,
      configs: GetStoreConfigDataAttributes
    ): void {
      state.currentStore = { ...configs }
      state.currentStore.DATE_FORMAT = convertPYDateFormatToJS(
        configs.DATE_FORMAT
      )

      // to mock a date in en-US format
      // state.currentStore.DATE_FORMAT = convertPYDateFormatToJS('%m/%d/%Y')
    },

    [ConfigMutations.DISCOUNTS](
      state: ConfigsState,
      discounts: GetDiscountsData[]
    ) {
      state.discounts = discounts
    },

    [ConfigMutations.RESET_STATE](state: ConfigsState) {
      Object.assign(state, initState())
    },

    [ConfigMutations.PRODUCT_CLASSES](
      state: ConfigsState,
      productClasses: GetItemsClassesAttributes[]
    ) {
      state.productClasses = productClasses
    },

    [ConfigMutations.DEVICE](state: ConfigsState, device: DeviceType) {
      state.device = device
    },

    [ConfigMutations.FABRICS](
      state: ConfigsState,
      fabrics: GetItemsFabricsAttributes[]
    ): void {
      state.fabrics = fabrics
    },

    [ConfigMutations.SAVE_STORE_CDN_MANIFEST](
      state: ConfigsState,
      cdnManifest: CdnManifest
    ): void {
      state.storeCdnManifest = cdnManifest
    },
  },

  getters: {
    [Getters.GET_MAX_PAYABLE_CASH_AMOUNT]: (state: ConfigsState): number => {
      return parseInt(state.currentStore?.MAX_MONEY_AMOUNT ?? '', 10)
    },
    [Getters.GET_MAX_PAYABLE_CASH_AMOUNT_EXTRA_AML]: (
      state: ConfigsState
    ): number => {
      return parseInt(
        state.currentStore?.MAX_MONEY_AMOUNT_EXTRA_EUROPE_AML ?? '',
        10
      )
    },
    [Getters.GET_MAX_PAYABLE_CASH_AMOUNT_EXTRA_COUNTRY]: (
      state: ConfigsState
    ): number => {
      return parseInt(
        state.currentStore?.MAX_MONEY_AMOUNT_EXTRA_COUNTRY ?? '',
        10
      )
    },
    [Getters.GET_STORE_SETUP]: (state: ConfigsState) => state.setup,
    [Getters.GET_STORE_CONFIGS]: (
      state: ConfigsState
    ): GetStoreConfigDataAttributes | undefined => {
      return state.currentStore
    },
    [Getters.FABRICS]: (state: ConfigsState): GetItemsFabricsAttributes[] => {
      return state.fabrics || []
    },
    [Getters.GET_STORES]: (state: ConfigsState): ReturnData[] => {
      return state.stores || []
    },
    [Getters.CLASSES]: (state: ConfigsState) => {
      return state.productClasses
    },
    [Getters.CLASS_DESCRIPTION]: (state: ConfigsState) => (classe: string) => {
      return state.productClasses.find((el) => el.classe === classe)
        ?.descrizione
    },
    [Getters.TIPI_VENDITA]: (
      state: ConfigsState
    ): Array<GetStoreConfigDataAttributesTipiVendita> => {
      // this could be simplified as enabled sale types are provided by the BE response to the initial config call.
      // We could simply return state.currentStore?.tipi_vendita

      return (
        state.currentStore?.tipi_vendita?.filter((t) => {
          const disabled =
            state.currentStore?.GIFT_USAGE !== '1' &&
            t.codice_movimento === TipiVendita.SCARICO_OMAGGIO
          const hidden =
            state.currentStore?.RETURNS_ENABLED === '0' &&
            (t.codice_movimento === TipiVendita.STORNO ||
              t.codice_movimento === TipiVendita.STORNO_TESSUTO)
          return !disabled && !hidden
        }) ?? []
      )
    },
    [Getters.TIPI_VENDITA_TESSUTO]: (
      state: ConfigsState
    ): Array<GetStoreConfigDataAttributesTipiVenditaTessuto> => {
      return state.currentStore?.tipi_vendita_tessuto || []
    },
    [Getters.LISTINO]: (state: ConfigsState): Array<string> => {
      return state.currentStore?.tipi_listino ?? []
    },
    [Getters.DEVICE]: (state: ConfigsState): DeviceType => {
      return state.device || 'pc'
    },
    [Getters.IS_MOBILE]: (state: ConfigsState): boolean => {
      return state.device !== 'pc'
    },
    [Getters.USE_LOGIN_PORTAL]: (state: ConfigsState): boolean => {
      return !!state.setup?.portal_login
    },
    [Getters.GET_PORTAL_URL]: (state: ConfigsState): string | undefined => {
      return state.setup?.portal_url
    },
    [Getters.INTEREST]: (
      state: ConfigsState
    ): GetStoreConfigDataAttributesConsumerInteressi[] | undefined => {
      return state.currentStore?.consumer?.interessi
    },
    [Getters.PROFESSION]: (
      state: ConfigsState
    ): GetStoreConfigDataAttributesConsumerProfessioni[] | undefined => {
      return state.currentStore?.consumer?.professioni
    },
    [Getters.MAX_DISCOUNT]: (state: ConfigsState): string => {
      return state.currentStore?.MAX_DISCOUNT_PERC ?? ''
    },
    [Getters.LISTINO_FILTRATO]:
      (state: ConfigsState) =>
      (filter: string[]): Array<string> => {
        return (
          state.currentStore?.tipi_listino?.filter(
            (l) => !filter.includes(l)
          ) ?? []
        )
      },
    [Getters.DEPLOY_ENV]: (state: ConfigsState) => {
      return state.setup?.deploy_env || 'live'
    },
    [Getters.IS_LIVE_ENVIRONMENT]: (
      _state: ConfigsState,
      getters: Record<Getters, string>
    ) => {
      const env = getters[Getters.DEPLOY_ENV]
      return env === 'live'
    },
    [Getters.SELECTED_CASH]: (state: ConfigsState) => state.setup?.cash_id,
    [Getters.GET_SELECTED_CASH_URL]: (state: ConfigsState) => {
      return state.selectedCashUrl
    },
    [Getters.STORE_CODE]: (state: ConfigsState) => {
      return state.currentStore?.STORE_CODE
    },
    [Getters.GET_STORE_DETAILS]: (
      state: ConfigsState
    ): GetStoreConfigDataAttributesStoreData | undefined => {
      const { STORE_CODE, store_data } = state.currentStore || {}
      return STORE_CODE && store_data ? store_data[STORE_CODE] : undefined
    },
    [Getters.GET_DISCOUNTS]: (state: ConfigsState): GetDiscountsData[] =>
      state.discounts,
    [Getters.GET_COUNTRY_CODE]: (state: ConfigsState): string =>
      state.currentStore?.COUNTRY_CODE || '',
    [Getters.GET_CURRENCY_SIGN]: (state: ConfigsState) =>
      state.currentStore?.CURRENCY_SIGN || '',
    [Getters.GET_GIFTCARD_MIN_AMOUNT]: (state: ConfigsState) =>
      parseFloat(state.currentStore?.GIFT_CARD_MIN_AMOUNT || '0'),
    [Getters.GET_GIFTCARD_MAX_AMOUNT]: (state: ConfigsState) =>
      parseFloat(state.currentStore?.GIFT_CARD_MAX_AMOUNT || '0'),
    [Getters.GET_CUSTOM_DATE]: (state: ConfigsState) =>
      state.currentStore?.CUSTOM_DATE,
    [Getters.GET_DATE_FORMAT]: (state: ConfigsState) =>
      state.currentStore?.DATE_FORMAT,
    [Getters.GET_LOCALE]: (state: ConfigsState) =>
      state.currentStore?.GUI_LANGUAGE_LOCALE
        ? state.currentStore?.GUI_LANGUAGE_LOCALE
        : 'it',
  },
}
