<script lang="ts">
import { defineComponent } from 'vue'
import { IonItem } from '@ionic/vue'
import feSelect from '@/components/global/select.vue'

export default defineComponent({
  components: { IonItem },

  props: {
    value: {
      type: String || null,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      index: undefined as number | undefined,
    }
  },

  computed: {
    isOptionSelected() {
      if (
        this.parentCurrentlySelected === null ||
        this.index === undefined ||
        this.parentCurrentlySelected !== this.index + 1
      ) {
        return false
      }

      if (this.parentSelectedValue) {
        this.handleClick()
      }

      this.$el.scrollIntoView({
        alignToTop: 'true',
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      })
      return true
    },
    /* TODO: QUESTA ROBA VA RIMOSSA QUANTO PRIMA */
    parentCurrentlySelected() {
      const parent = this.$parent?.$parent as typeof feSelect | undefined
      return parent?.currentlyFocused || null
    },
    /* TODO: QUESTA ROBA VA RIMOSSA QUANTO PRIMA */
    parentSelectedValue() {
      const parent = this.$parent?.$parent as typeof feSelect | undefined
      return parent?.selectValue || null
    },
  },

  mounted() {
    this.getCurrentIndex()
  },
  updated() {
    this.getCurrentIndex()
  },

  methods: {
    getCurrentIndex() {
      /* TODO: QUESTA ROBA VA RIMOSSA QUANTO PRIMA */
      // Obtain current element's index
      const parent = this.$parent?.$parent as typeof feSelect | undefined
      let optionElements = parent?.$el.querySelectorAll('ion-list > ion-item')
      optionElements.forEach((element: unknown, index: number) => {
        if (element === this.$el) {
          this.index = index
        }
      })
    },
    handleClick() {
      this.$parent?.$emit('change', this.value)
    },
  },
})
</script>

<template>
  <ion-item :disabled="disabled" @mousedown="handleClick">
    <div
      class="select__option"
      :class="{ 'select__option-selected': isOptionSelected }"
    >
      <slot />
    </div>
  </ion-item>
</template>

<style lang="scss" scoped>
@import '@/styles/_constants.scss';

ion-item {
  --min-height: 0;
  --inner-border-width: 0;
  cursor: pointer;
  border-bottom: 0.1rem solid $color-light-grey;
}

.select__option {
  width: 100%;
  padding: 0.6rem 1.6rem 0.4rem 1.6rem;
  margin: 0;

  &-selected {
    background-color: #aaa;
  }
}
ion-item {
  margin-bottom: 0;
}
</style>
