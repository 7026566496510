import { salesListApi } from '@/addons/axios'
import {
  ApiV1SalesData,
  ContentAsListOfSalesAttributes,
  GetCashiersAttributes,
} from '@/api'
import { ActionContext } from 'vuex'
import { RootState } from './index'
import { NotificationsActions } from '@/store/notifications-store'
import { printFile } from '@/addons/functions'
export interface SalesListState {
  salesList: ContentAsListOfSalesAttributes[]
  filters: SalesListFilter
  isLoading: boolean
}

export interface SalesListFilter {
  filterDataBollettinaDa: string
  filterDataBollettinaA: string
  filterNumeroBollettina: string
  filterfiscalNumber: string
  filterBarcode: string
  filterCognome: string
  filterNome: string
  filterCodDocumento: string
  filterRdv: GetCashiersAttributes | undefined
}

enum Mutations {
  RESET_STATE = 'resetState',
  SET_SEARCH_FORM = 'setfilters',
  RESET_FILTER = 'resetFilter',
  SET_FILTER_VALUE = 'setFilterValue',
  SET_LIST_SALES = 'setListSales',
  SET_LOADING_DATA = 'setLoadingData',
}

enum Actions {
  RESET_STATE = 'resetState',
  RESET_FILTER = 'resetFilter',
  SET_FILTER_VALUE = 'setFilterValue',
  SEARCH_SALES = 'searchSales',
  PRINT_INVOICE = 'printInvoice',
  PRINT_BOLLETTINA = 'printOss',
  PRINT_SCONTRINO_CORTESIA = 'printScontrinoCortesia',
  PRINT_SALES_RECEIPT = 'printSalesReceipt',
  PRINT_SHIPPING_DOCUMENT = 'printShippingDocument',
  PRINT_INTERNAL_TICKET_LOTTERY = 'printInternalTicketLottery',
  PRINT_NON_FISCAL_INVOICE = 'reprintNonFiscalInvoice',
}

enum Getters {
  GET_SALE_LIST = 'salesList',
  GET_FILTERS = 'getFilters',
  IS_LOADING_DATA = 'isLoading',
}

const initFilter = (): SalesListFilter => {
  return {
    filterDataBollettinaDa: '',
    filterDataBollettinaA: '',
    filterNumeroBollettina: '',
    filterfiscalNumber: '',
    filterBarcode: '',
    filterCognome: '',
    filterNome: '',
    filterCodDocumento: '',
    filterRdv: undefined,
  }
}

const initState = (): SalesListState => ({
  salesList: [],
  filters: initFilter(),
  isLoading: false,
})

const salesListStore = {
  namespaced: true,
  state: initState,
  mutations: {
    [Mutations.RESET_STATE]: (state: SalesListState): void => {
      Object.assign(state, initState())
    },
    [Mutations.RESET_FILTER]: (state: SalesListState): void => {
      state.filters = initFilter()
    },
    [Mutations.SET_FILTER_VALUE]: (
      state: SalesListState,
      payload: object
    ): void => {
      const temp = { ...state.filters }
      temp[payload.name] = payload.value
      state.filters = temp
    },
    [Mutations.SET_LIST_SALES]: (
      state: SalesListState,
      salesList: ContentAsListOfSalesAttributes[]
    ): void => {
      state.salesList = salesList
    },
    [Mutations.SET_LOADING_DATA]: (
      state: SalesListState,
      value: boolean
    ): void => {
      state.isLoading = value
    },
  },
  actions: {
    [Actions.RESET_STATE]: (
      context: ActionContext<SalesListState, RootState>
    ): void => {
      context.commit(Mutations.RESET_STATE)
    },
    [Actions.RESET_FILTER]: (
      context: ActionContext<SalesListState, RootState>
    ): void => {
      context.commit(Mutations.RESET_FILTER)
    },
    [Actions.SET_FILTER_VALUE]: (
      context: ActionContext<SalesListState, RootState>,
      payload: object
    ): void => {
      context.commit(Mutations.SET_FILTER_VALUE, payload)
    },
    [Actions.SEARCH_SALES]: async (
      context: ActionContext<SalesListState, RootState>
    ): Promise<void> => {
      context.commit(Mutations.SET_LOADING_DATA, true)
      try {
        const response = await salesListApi.apiV1ListSalesGet(
          context.state.filters.filterDataBollettinaDa
            ? context.state.filters.filterDataBollettinaDa.replaceAll(/-/g, '')
            : undefined,
          context.state.filters.filterDataBollettinaA
            ? context.state.filters.filterDataBollettinaA.replaceAll(/-/g, '')
            : undefined,
          context.state.filters.filterNumeroBollettina === ''
            ? undefined
            : parseInt(context.state.filters.filterNumeroBollettina, 10),
          context.state.filters.filterCognome || undefined,
          context.state.filters.filterNome || undefined,
          context.state.filters.filterBarcode || undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          context.state.filters.filterCodDocumento || undefined,
          context.state.filters.filterfiscalNumber || undefined,
          context.state.filters.filterRdv?.venditrice || undefined
        )
        const sales = (response.data as unknown as { data: ApiV1SalesData[] })
          .data
        context.commit(
          Mutations.SET_LIST_SALES,
          sales.map((el) => el.attributes)
        )
      } catch (e) {
        context.dispatch(NotificationsActions.NOTIFY_ERROR, e, { root: true })
      } finally {
        context.commit(Mutations.SET_LOADING_DATA, false)
      }
    },
    [Actions.PRINT_INVOICE]: async (
      context: ActionContext<SalesListState, RootState>,
      transactionId: string
    ): Promise<void> => {
      await salesListApi.apiV1ListSalesPrintInvoiceKeyGet(transactionId)
    },
    [Actions.PRINT_BOLLETTINA]: async (
      context: ActionContext<SalesListState, RootState>,
      transactionId: string
    ): Promise<void> => {
      await salesListApi.apiV1RistampaBollettinaGet(transactionId)
    },
    [Actions.PRINT_SCONTRINO_CORTESIA]: async (
      context: ActionContext<SalesListState, RootState>,
      transactionId: string
    ): Promise<void> => {
      await salesListApi.apiV1RistampaScontrinoCortesiaGet(transactionId)
    },
    [Actions.PRINT_SALES_RECEIPT]: async (
      context: ActionContext<SalesListState, RootState>,
      transactionId: string
    ) => {
      const storeCode = context.rootState.configs.currentStore?.STORE_CODE
      await salesListApi.apiV1SalesSaleIdReceiptPatch(
        `${storeCode},${transactionId}`
      )
    },
    [Actions.PRINT_SHIPPING_DOCUMENT]: async (
      context: ActionContext<SalesListState, RootState>,
      transactionId: string
    ) => {
      const pdfData = await salesListApi.apiV1ListSalesPrintPdfOssKeyGet(
        transactionId
      )
      if (pdfData?.data?.data?.attributes?.pdf_content) {
        printFile(pdfData.data.data.attributes)
      }
    },
    [Actions.PRINT_INTERNAL_TICKET_LOTTERY]: async (
      context: ActionContext<SalesListState, RootState>,
      transactionId: string
    ) => {
      await salesListApi.apiV1RistampaFiscaleGet(transactionId)
    },
    [Actions.PRINT_NON_FISCAL_INVOICE]: async (
      context: ActionContext<SalesListState, RootState>,
      transactionId: string
    ) => {
      const pdfData = await salesListApi.apiV1ListSalesPrintInvoiceKeyGet(
        transactionId
      )

      if (pdfData?.data?.data?.attributes?.pdf_content) {
        printFile(pdfData.data.data.attributes)
      }
    },
  },
  getters: {
    [Getters.GET_SALE_LIST]: (state: SalesListState) => state.salesList,
    [Getters.GET_FILTERS]: (state: SalesListState) => state.filters,
    [Getters.IS_LOADING_DATA]: (state: SalesListState) => state.isLoading,
  },
}

export default salesListStore

export const SalesListActions = {
  RESET_STATE: `salesList/${Actions.RESET_STATE}`,
  RESET_FILTER: `salesList/${Actions.RESET_FILTER}`,
  SET_FILTER_VALUE: `salesList/${Actions.SET_FILTER_VALUE}`,
  SEARCH_SALES: `salesList/${Actions.SEARCH_SALES}`,
  PRINT_INVOICE: `salesList/${Actions.PRINT_INVOICE}`,
  PRINT_BOLLETTINA: `salesList/${Actions.PRINT_BOLLETTINA}`,
  PRINT_SCONTRINO_CORTESIA: `salesList/${Actions.PRINT_SCONTRINO_CORTESIA}`,
  PRINT_SALES_RECEIPT: `salesList/${Actions.PRINT_SALES_RECEIPT}`,
  PRINT_SHIPPING_DOCUMENT: `salesList/${Actions.PRINT_SHIPPING_DOCUMENT}`,
  PRINT_INTERNAL_TICKET_LOTTERY: `salesList/${Actions.PRINT_INTERNAL_TICKET_LOTTERY}`,
  PRINT_NON_FISCAL_INVOICE: `salesList/${Actions.PRINT_NON_FISCAL_INVOICE}`,
}

export const SalesListGetters = {
  GET_SALE_LIST: `salesList/${Getters.GET_SALE_LIST}`,
  GET_FILTERS: `salesList/${Getters.GET_FILTERS}`,
  IS_LOADING_DATA: `salesList/${Getters.IS_LOADING_DATA}`,
}

export const SalesListMutations = {
  RESET_STATE: `salesList/${Mutations.RESET_STATE}`,
  SET_FILTER_VALUE: `salesList/${Mutations.SET_FILTER_VALUE}`,
}
