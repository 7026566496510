import { useStore } from 'vuex'
import { getPathFromCDN } from '@/services/cdnClient'
import { ErrorD } from '@/addons/interface'
import { NotificationsActions } from '@/store/notifications-store'
import { FilterTypeMapE } from '@/modules/endless-aisle/store/index.d'
import { GetModelsAttributes, GetModelsAttributesSizes } from '@/api'
import I18n from '@/addons/i18n'

export const getImgSrc = (imgName: string): Promise<string> =>
  getPathFromCDN(imgName) as Promise<string>

export async function handleCatchRequest(err: unknown): Promise<void> {
  const store = useStore()
  const error = err as ErrorD
  await store.dispatch(
    NotificationsActions.NOTIFY_ERROR,
    (error.response?.data?.errors || [])[0]?.detail
  )
}

export function mapFilterType(code: string) {
  const filterMatrix = [
    [FilterTypeMapE.RANGE, ['prices', 'price']],
    [FilterTypeMapE.TOGGLE, ['modelB2C', 'newArrivals', 'onSale', 'stock']],
    [FilterTypeMapE.SELECT, ['collection', 'mainline']],
    [
      FilterTypeMapE.MULTISELECT,
      [
        'colorFilter',
        'sizes',
        'sleeveLengthFilter',
        'typefilter',
        'yearSeason',
        'occasionFilter',
      ],
    ],
  ]
  return (
    (filterMatrix.find((el) => {
      const [, e2] = el
      return e2.includes(code)
    }) || [])[0] || FilterTypeMapE.MULTISELECT
  )
}

// TODO: add right interface file
export function convertImgToBase64(
  file: any
): Promise<string | ArrayBuffer | null> {
  const reader = new FileReader()
  return new Promise((resolve) => {
    reader.onload = function () {
      resolve(reader.result)
    }
    reader.readAsDataURL(file)
  })
}

export function findCurrSku(
  data: GetModelsAttributes,
  index = 0
): GetModelsAttributesSizes {
  return (((data?.variants || [])[0] || {}).sizes || [])[index] || {}
}

export function getFitlersKey(k: string): string {
  let filterString = k.charAt(0).toUpperCase() + k.slice(1)
  const indexOfUnderscore = filterString.indexOf('_')
  if (indexOfUnderscore > -1) {
    const split_ = filterString.split('_')
    const lastWord = split_[1].charAt(0).toUpperCase() + split_[1].slice(1)
    filterString = split_[0] + lastWord
  }
  return filterString
}

export function renderYearSeason(s: string): string {
  if (s) {
    const ps = s[s.length - 1]
    const y = s.substring(4, 0)
    return `${
      ps === '1'
        ? I18n.global.t('mmfg_season.s_s')
        : I18n.global.t('mmfg_season.f_w')
    } ${y}`
  }
  return s
}
export const confRenderLabel = {
  yearSeason: renderYearSeason,
}
