import cashierStore, {
  CashierState,
  CashiersActions,
  NAMESPACE as cashierNamespace,
} from './cashier'
import configsStore, {
  ConfigActions,
  ConfigsState,
} from '@/store/configs-store'
import drawerStore, { DrawerActions, DrawerState } from '@/store/drawer-store'
import { Module, createStore } from 'vuex'

import { CookieKeys } from '@/addons/enums'
import { deleteCookie } from '@/addons/functions'
import i18n, { setLanguage } from '@/addons/i18n'
import authStore, {
  AuthActions,
  AuthState,
  AuthStore,
  NAMESPACE as authNamespace,
} from './auth'
import cashMovementsStore, {
  CashMovementsActions,
  CashMovementsState,
} from '@/store/cash-movements-store'
import connectionStore, { ConnectionState } from '@/store/connection-store'
import laybyStore, { LaybyActions, LaybyState } from '@/store/layby-store'
import menuStore, { MenuActions, MenuState } from '@/store/menu-store'
import notificationsStore, {
  NotificationsState,
} from '@/store/notifications-store'
import paymentsStore, {
  PaymentsActions,
  PaymentsState,
} from '@/store/payments-store'
import pettyCashStore, {
  PettyCashActions,
  PettyCashState,
} from '@/store/petty-cash-store'
import productStore, {
  ProductActions,
  ProductState,
} from '@/store/product/product-store'
import salesListStore, {
  SalesListActions,
  SalesListState,
} from '@/store/sales-list-store'

import giftStore, { GiftActions, GiftState } from '@/store/gift-store'
import refundB2EListStore, {
  RefundB2EListActions,
  RefundB2EListState,
} from '@/store/refund-b2e-list-store'

import { prepareApiHelpers } from '@/addons/axios'
import salesStore, { SalesActions, SalesState } from '@/store/sales/sales-store'
import stockStore, { StockState } from '@/store/stock-store'
import bankStore, { BankState, BanksActions } from './bank-store'
import genericStore, { GenericActions, GenericState } from './generic-store'
import suspendedCreditStore, {
  SuspendedCreditActions,
  SuspendedCreditState,
  SuspendedCreditStore,
  NAMESPACE as suspendedCreditNamespace,
} from './suspended-credit'
import contextPageStore, {
  ContextPageState,
} from '@/store/context-page/context-page'
import usersStore, { UsersActions, UsersState } from '@/store/users-store'
import returnsStore, {
  ReturnsActions,
  ReturnsState,
} from '@/store/returns/returns-store'
import storeStatusStore, { StoreStatusState } from '@/store/store-status-store'
import tools, { ToolsActions, ToolsState } from '@/store/tools'
import loyaltyStore, {
  // LoyaltyActions,
  LoyaltyState,
  LoyaltyStore,
  NAMESPACE as loyaltyNamespace,
} from '@/modules/loyalty/store'
import sidebarStore, {
  SidebarState,
  NAMESPACE as sidebarNamepace,
} from '@/store/sidebar'
import consumersStore, {
  ConsumersStore,
  ConsumersState,
  NAMESPACE as consumersNamespace,
  ConsumersActions,
} from '@/store/consumer'
import { clearLocalStorage } from '@/addons/persistence'
export interface RootState {
  auth: AuthState
  cashiers: CashierState
  drawer: DrawerState
  notifications: NotificationsState
  connection: ConnectionState
  menu: MenuState
  generic: GenericState
  configs: ConfigsState
  stock: StockState
  sales: SalesState
  payments: PaymentsState
  product: ProductState
  banks: BankState
  returns: ReturnsState
  cashMovements: CashMovementsState
  salesList: SalesListState
  pettyCash: PettyCashState
  gift: GiftState
  refundB2EList: RefundB2EListState
  suspendedCredit: SuspendedCreditState
  layby: LaybyState
  contextPage: ContextPageState
  storeStatus: StoreStatusState
  users: UsersState
  tools: ToolsState
  loyalty: LoyaltyState
  sidebar: SidebarState
  consumers: ConsumersState
}

// XXX: this Store type should inherit from the original type from vuex
// otherwise will break all the original methods and properties such as dispatch
type Store =
  | SuspendedCreditStore<Pick<RootState, 'suspendedCredit'>>
  | AuthStore<Pick<RootState, 'auth'>>
  | LoyaltyStore<Pick<RootState, 'loyalty'>>
  | ConsumersStore<Pick<RootState, 'consumers'>>

const store: Store = createStore({
  actions: {
    switchLanguage(context, { language, localesModules, json }): void {
      setLanguage(i18n, language, localesModules, json)
    },
  },
  // const store = createStore({
  mutations: {
    async resetState(): Promise<void> {
      // Delete any previously saved state
      sessionStorage.clear()
      clearLocalStorage()

      await deleteCookie(CookieKeys.SID)

      // Wait until each reset action has done its work
      await Promise.all([
        store.dispatch(ProductActions.RESET_STATE),
        store.dispatch(SalesActions.RESET_STATE),
        store.dispatch(AuthActions.RESET_STATE),
        store.dispatch(CashiersActions.RESET_STATE),
        store.dispatch(ConfigActions.RESET_STATE),
        store.dispatch(ConsumersActions.RESET_STATE),
        store.dispatch(DrawerActions.RESET_STATE),
        store.dispatch(GenericActions.RESET_STATE),
        store.dispatch(MenuActions.RESET_STATE),
        store.dispatch(PaymentsActions.RESET_STATE),
        store.dispatch('stock/resetState'),
        store.dispatch(CashMovementsActions.RESET_STATE),
        store.dispatch(BanksActions.RESET_STATE),
        store.dispatch(PettyCashActions.RESET_STATE),
        store.dispatch(SalesListActions.RESET_STATE),
        store.dispatch(RefundB2EListActions.RESET_STATE),
        store.dispatch(GiftActions.RESET_STATE),
        store.dispatch(SuspendedCreditActions.RESET_STATE),
        store.dispatch(UsersActions.RESET_STATE),
        store.dispatch(ReturnsActions.RESET_STATE),
        store.dispatch(LaybyActions.RESET_STATE),
        // store.dispatch(LaybyActions.RESET_STATE),
        store.dispatch(ToolsActions.RESET_STATE),
      ])

      // At this point we can reset API helpers so that user can eventually log back in.
      await prepareApiHelpers()
    },
  },
  getters: {},
  modules: {
    [authNamespace]: authStore,
    [cashierNamespace]: cashierStore,
    [consumersNamespace]: consumersStore as Module<ConsumersState, unknown>,
    drawer: drawerStore as Module<DrawerState, unknown>,
    notifications: notificationsStore as Module<NotificationsState, unknown>, // TS does't like, no idea why
    connection: connectionStore as Module<ConnectionState, unknown>,
    menu: menuStore as Module<MenuState, RootState>,
    generic: genericStore as Module<GenericState, unknown>,
    configs: configsStore as Module<ConfigsState, RootState>,
    stock: stockStore as Module<StockState, unknown>,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    product: productStore as Module<ProductState, unknown>, // TS does't like, no idea why
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    sales: salesStore as Module<SalesState, unknown>, // TS does't like, no idea why
    payments: paymentsStore,
    layby: laybyStore,
    cashMovements: cashMovementsStore as Module<CashMovementsState, unknown>,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    returns: returnsStore as Module<ReturnsState, unknown>, // TS doesn't like, no idea why
    banks: bankStore,
    pettyCash: pettyCashStore as Module<PettyCashState, unknown>,
    salesList: salesListStore,
    refundB2EList: refundB2EListStore,
    gift: giftStore,
    [suspendedCreditNamespace]: suspendedCreditStore,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    contextPage: contextPageStore as Module<ContextPageState, unknown>,
    storeStatus: storeStatusStore as Module<StoreStatusState, RootState>,
    users: usersStore as Module<UsersState, unknown>,
    tools: tools as Module<ToolsState, unknown>,
    [loyaltyNamespace]: loyaltyStore,
    [sidebarNamepace]: sidebarStore as Module<SidebarState, RootState>,
  },
})
/** @function addModuleInStore
 * @param name - the module's path
 * @param module - the module that need to be added
 * Add the new module in store
 */
export function addModuleInStore(
  name: string,
  module: Module<any, unknown>
): void {
  store.registerModule(name, module)
}

export default store
