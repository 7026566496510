<script lang="ts">
import { getBaseURL, getSoftwareRevision } from '@/addons/functions'
import { isNativeIos, LiveUpdatesHelper } from '@/addons/mobile'
import { isLoginPage } from '@/router'
import { ConfigGetters } from '@/store/configs-store'
import { StatusBar } from '@capacitor/status-bar'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { ConnectionGetters } from '@/store/connection-store'

export default defineComponent({
  name: 'FeHeader',
  data() {
    return {
      // Note: since this is real world time, this should reflect system time, NOT the custom date.
      wallClockTime: new Date(),
      intervalId: -1,

      shouldAccountForStatusBar: isNativeIos(),
      swRevision: '',

      clicksOnVersionNumber: 0,
    }
  },

  computed: {
    ...mapGetters({
      cashCode: ConfigGetters.SELECTED_CASH,
      shopCode: ConfigGetters.STORE_CODE,
      isOnline: ConnectionGetters.GET_CONNECTION_IS_ONLINE,
      storeSetup: ConfigGetters.GET_STORE_SETUP,
    }),
    brand(): string {
      const [currentStore] = this.storeSetup?.stores || []
      const storeSign = currentStore?.store_sign || ''
      return storeSign
    },
    logoImagePath(): string {
      return this.brand
        ? `${getBaseURL()}/assets/img/brands/${this.brand}/logo.svg`
        : ''
    },
    isLoginPage() {
      return isLoginPage()
    },
  },

  mounted() {
    if (isNativeIos()) {
      this.checkIfStatusBarIsVisible()
    }

    this.intervalId = window.setInterval(() => {
      // Note: since this is real world time, this should reflect system time, NOT the custom date.
      this.wallClockTime = new Date()
    }, 1000)

    this.getSoftwareRevision()
  },

  unmounted() {
    window.clearInterval(this.intervalId)
    this.intervalId = -1
  },

  methods: {
    async checkIfStatusBarIsVisible() {
      const info = await StatusBar.getInfo()
      this.shouldAccountForStatusBar = info.visible
    },
    async getSoftwareRevision() {
      this.swRevision = await getSoftwareRevision()
    },
    onVersionClick: LiveUpdatesHelper.handleVersionClick,
  },
})
</script>

<template>
  <header :class="{ offline: !isOnline }">
    <div
      class="left"
      :class="{ statusBarPadding: shouldAccountForStatusBar }"
      @click="onVersionClick()"
    >
      <template v-if="!isLoginPage">
        <span>{{ shopCode }}</span>
        <span> - </span>
        <span>{{ cashCode }}</span>
        <span v-if="swRevision"> - </span>
      </template>
      <template v-if="swRevision">
        <span>v</span>
        <span>{{ swRevision }}</span>
      </template>
    </div>

    <div class="logo">
      <img
        v-if="logoImagePath"
        class="logo__image"
        :src="logoImagePath"
        alt="Logo"
      />
      <span class="logo__placeholder"></span>
    </div>

    <div class="right" :class="{ statusBarPadding: shouldAccountForStatusBar }">
      <span>{{ $d(wallClockTime, 'datetime') }}</span>
      <div class="connection">
        <fe-icon
          v-show="isOnline"
          class="connection__icon"
          icon="online"
        ></fe-icon>
        <fe-icon
          v-show="!isOnline"
          class="connection__icon"
          icon="offline"
        ></fe-icon>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
@import '@/styles/_constants.scss';

header {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  padding: 0 1.6rem;
  box-sizing: border-box;
  border-bottom: 0.1rem solid $color-black;
  background-color: $color-white;

  & .left,
  & .right {
    font-size: small;
  }

  &.offline {
    background-color: $color-offline;
  }

  & .right {
    grid-column: 3;
    display: flex;
    justify-content: flex-end;

    & .connection {
      margin-left: 1.6rem;
      font-size: initial;

      &__icon {
        width: 2.2rem;
        height: 2.2rem;
      }
    }
  }
}

.logo {
  grid-column: 2;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  box-sizing: border-box;

  &__image,
  &__placeholder {
    height: 2.8rem;
  }
}

.statusBarPadding {
  padding-top: var(--ion-statusbar-padding, 0);
}
</style>
