import { GetterTree } from 'vuex'
import { RootState } from '@/store'
import { State } from './_state'
import {
  GetConsumerAttributes,
  GetConsumerAttributesUltimeModifiche,
} from '@/api'
import { AnonymousConsumer } from '@/interfaces/anonymous-consumer'
import { Statistics } from '@/store/sales/sales-store'

export enum GetterNames {
  CONSUMERS_LIST = 'consumers-list',
  ANONYMOUS_CONSUMER_LIST = 'anonymous-consumer-list',
  GET_LAST_CHANGE_CONSUMER = 'get-last-change-consumer',
  GET_CURRENT_CONSUMER = 'get-current-consumer',
  GET_DATA_VISIBILITY = 'get-data-visibility',
  GET_SELECTED_CONSUMER_STATUS = 'get-selected-consumer-status',
  GET_IS_JUST_CREATED = 'get-is-just-created',
  GET_NATIONS_ANONYMOUS = 'get-nations-anonymous',
}
export type Getters = {
  [GetterNames.CONSUMERS_LIST](state: State): State['consumers']
  [GetterNames.ANONYMOUS_CONSUMER_LIST](
    state: State
  ): State['anonymousConsumer']
  [GetterNames.GET_LAST_CHANGE_CONSUMER](
    state: State
  ): State['lastChangeConsumer']
  [GetterNames.GET_CURRENT_CONSUMER](state: State): State['currentConsumer']
  [GetterNames.GET_DATA_VISIBILITY](state: State): State['consumerStatistics']
  [GetterNames.GET_SELECTED_CONSUMER_STATUS](
    state: State
  ): State['selectedConsumerStatus']
  [GetterNames.GET_IS_JUST_CREATED](state: State): State['isJustCreated']
  [GetterNames.GET_NATIONS_ANONYMOUS](state: State): State['nationsAnonymous']
}
export const getters: GetterTree<State, RootState> = {
  [GetterNames.CONSUMERS_LIST]: (state: State): GetConsumerAttributes[] =>
    state.consumers,
  [GetterNames.ANONYMOUS_CONSUMER_LIST]: (state: State): AnonymousConsumer[] =>
    state.anonymousConsumer,
  [GetterNames.GET_LAST_CHANGE_CONSUMER]: (
    state: State
  ): GetConsumerAttributesUltimeModifiche[] => state.lastChangeConsumer,
  [GetterNames.GET_CURRENT_CONSUMER]: (state: State): GetConsumerAttributes =>
    state.currentConsumer || {},
  [GetterNames.GET_DATA_VISIBILITY]: (state: State): Statistics | undefined =>
    state.consumerStatistics,
  [GetterNames.GET_SELECTED_CONSUMER_STATUS]: (
    state: State
  ): State['selectedConsumerStatus'] => state.selectedConsumerStatus,
  [GetterNames.GET_IS_JUST_CREATED]: (state: State): State['isJustCreated'] =>
    state.isJustCreated,
  [GetterNames.GET_NATIONS_ANONYMOUS]: (
    state: State
  ): State['nationsAnonymous'] => state.nationsAnonymous,
}
