import { storeDataApi } from '@/addons/axios'
import { GetCashiersAttributes } from '@/api'
import { ActionTree } from 'vuex'
import { TypedActionContext } from '../_types'
import { RootState } from '@/store'
import { MutationNames, Mutations } from './_mutations'
import { State } from './_state'

export enum ActionNames {
  RESET_STATE = 'resetState',
  FETCH_CASHIERS = 'fetchCashiers',
}

type AugmentedActionContext = TypedActionContext<Mutations, State, RootState>

export interface Actions {
  [ActionNames.RESET_STATE](context: AugmentedActionContext): void
  [ActionNames.FETCH_CASHIERS](context: AugmentedActionContext): void
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionNames.RESET_STATE](context) {
    context.commit(MutationNames.RESET_STATE)
  },
  [ActionNames.FETCH_CASHIERS]: async (context) => {
    const filter = (c?: GetCashiersAttributes) => {
      return c?.descrizione || c?.descrizione_kana || c?.descrizione_kanji
    }
    const response = await storeDataApi.apiV1PoswebCashiersGet()
    const cashiers = response.data.data
      ?.filter((c) => filter(c.attributes))
      ?.map((c) => c.attributes)

    context.commit(MutationNames.STORE_CASHIERS, cashiers)
  },
}
