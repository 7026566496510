import { consumerDataApi, salesApi } from '@/addons/axios'
import i18n from '@/addons/i18n'
import {
  GetConsumerAttributes,
  GetMenuAttributesSubmenu,
  GetMenuData,
} from '@/api'
import LoaderController from '@/components/loader/LoaderController'
import modalController, { ModalTypes } from '@/components/modal/ModalController'
import { ADDITIONAL_BMS_PAGES } from '@/configs'
import { SCSS_MIXINS } from '@/main'
import router, { Routes } from '@/router'
import store from '@/store'
import { AuthGetters } from '@/store/auth'
import { ConfigGetters } from '@/store/configs-store'
import { ContextPageGetters } from '@/store/context-page/context-page'
import { MenuGetters } from '@/store/menu-store'
import { NotificationsActions } from '@/store/notifications-store'
import { AppPreferences } from '@awesome-cordova-plugins/app-preferences'
import { FileOpener } from '@capacitor-community/file-opener'
import { App } from '@capacitor/app'
import { Browser } from '@capacitor/browser'
import { CapacitorCookies } from '@capacitor/core'
import { Directory, Filesystem } from '@capacitor/filesystem'
import { NavigationFailure } from 'vue-router'
import {
  CookieKeys,
  LanguagesIso,
  LanguagesPosweb,
  MobileDeviceSettingKeys,
  PageContexts,
  PaymentsModules,
} from './enums'
import checkDevice, {
  DeviceTypeMediaQueryNames,
  isNativeMobileApp,
  LiveUpdatesHelper,
} from './mobile'

/**
 * Returns current base url without any leading slash.
 * This allows us to load assets from the "public/assets" folder by
 * writing URLs like this one: `${getBaseURL()}/assets/icons/${icon}.svg`
 *
 * More precisely:
 *  - if the application is being executed in a browser, this will probably return "/frontend".
 *  - if it's a native app (e.g. on iOS or Android), this will return an empty string.
 */
export function getBaseURL() {
  let baseUrl: string = import.meta.env.BASE_URL || ''
  if (baseUrl?.endsWith('/')) {
    baseUrl = baseUrl.replace(/\/$/g, '')
  }

  return baseUrl
}

/**
 * Returns Posweb HTTP origin.
 * This allows us to make requests to the correct HTTP origin.
 * This could change based on certain settings, so here is a bief gist of this process:
 *
 *  - if we are in dev and we have an environment variabile set, we use that env variable
 *  - if we are in a native application, we can get the appropriate URL by asking the OS
 *  - otherwise we fallback to the current origin
 *
 * If we are in the last condition, the frontend application and the backend server are hosted by the
 * same machine, so we do not have to make requests to another origin.
 */
export async function getPoswebOrigin(): Promise<string> {
  let poswebUrl = ''

  if (import.meta.env.DEV && import.meta.env.VITE_POSWEB_INSTANCE_URL) {
    poswebUrl = import.meta.env.VITE_POSWEB_INSTANCE_URL
  } else if (isNativeMobileApp()) {
    poswebUrl = (await AppPreferences.fetch(
      MobileDeviceSettingKeys.POSWEB_URL
    )) as string
  } else if (import.meta.env.VITE_OVERRIDE_API_URL) {
    // BBB: let's use an env variable to override the API URL
    // this way it is possibile to have FE environment outside the Posweb python installation.
    poswebUrl = import.meta.env.VITE_OVERRIDE_API_URL
  } else {
    poswebUrl = window.location.origin
  }

  return poswebUrl.replace(/\/$/, '')
}

/**
 * Returns current software version.
 * This is how this value is computed:
 *  - we read it from an environment variabile, falling back to the string `0.0.0.00`
 *  - if we are a native mobile application, we ask the OS for the version and build number
 *  - if we are a native mobile application and we installed a live update, we also append its' build id
 */
export async function getSoftwareRevision(): Promise<string> {
  let version = import.meta.env.VITE_GIT_REVISION?.trim() || '0.0.0.00'

  if (isNativeMobileApp()) {
    const info = await App.getInfo()
    const build = info.build?.padStart(2, '0') || '00'
    version = `${info.version}.${build}`

    const liveUpdateBuildId = LiveUpdatesHelper.buildId
    if (liveUpdateBuildId) {
      version += `:${liveUpdateBuildId}`
    }
  }

  return version
}

/**
 * Extract error key from error message from posweb backend and remove html tags
 * @example "[['anagrafica_consumatrice', 'Consumatrice non valida']]" - output: 'anagrafica_consumatrice'
 * @param error tupla returned from Posweb in errors[0].detail
 * @return message - string with error message
 */
export function extractErrorMessage(error: string): string {
  try {
    const matches = error.match(/["|'](.+)["|'],\s*["|'](.+)["|']/)
    if (matches?.length) {
      const result = matches[matches.length - 1]
        .replace(/^\\{0,2}/, '')
        .replace(/\\{0,2}$/, '')
        .replace(/<\/*br(?:\s\/)*>/g, '\n')
        .replace(/\\'/g, `'`)

      return parseHTML(result)
    }

    throw error
  } catch (e: unknown) {
    const result = parseHTML(error.replaceAll(`'`, '"'))
    return result
  }
}

/**
 * Parses html code sent from backend
 * @exmaple input: '&#128;' - output: '€'
 * @example input: '<div class="test">Value</div>' - output: 'Value'
 * @param htmlString A string containing some HTML
 */
export function parseHTML(htmlString: string): string {
  const parser = new DOMParser()
  const result = parser.parseFromString(htmlString, 'text/html')
  const parsed = result.documentElement.textContent || ''

  return parsed
}

/**
 * Extract translation code (<cluster>.<id>) from HTML <trad> element
 * @example "<trad group='mmfg_menu' id='frontoffice'>FrontOffice</trad>" -> "mmfg_menu.frontoffice"
 * @param originalLabel label to extract from HTML (<trad group='mmfg_menu' id='frontoffice'>FrontOffice</trad>)
 * @return <cluster>.<id> string to translate
 */
export function labelContent(originalLabel: string): string {
  const el = document.createElement('div')
  el.innerHTML = originalLabel
  const child = el.firstElementChild

  return `${child?.getAttribute('group')}.${child?.getAttribute('id')}`
}

export function setCookie(
  name: string,
  value: string,
  ms?: number
): Promise<void> {
  let expires = undefined
  if (ms) {
    const date = new Date()
    date.setTime(date.getTime() + ms)
    expires = date.toUTCString()
  }

  if (isNativeMobileApp()) {
    return CapacitorCookies.setCookie({
      key: name,
      value: `${value}`,
      expires: expires,
    })
  }

  document.cookie = `${name}=${value || ''}; expires=${expires}; path=/`
  return Promise.resolve()
}

export async function getCookie(name: string): Promise<string | undefined> {
  if (isNativeMobileApp()) {
    const cookies = (await CapacitorCookies.getCookies()) || {}
    return cookies[name]
  }

  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift()
  }

  return Promise.resolve(undefined)
}

export async function deleteCookie(name: string) {
  if (isNativeMobileApp()) {
    await CapacitorCookies.deleteCookie({ key: name })
  }

  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
  return Promise.resolve()
}

export async function getSid() {
  const cookie = (await getCookie(CookieKeys.SID)) || ''
  const result = cookie.match(/(?:"|')*sid=([A-Za-z0-9]*)(?:"|')*/)

  if (result && result.length >= 2) {
    return result[1]
  }

  return Promise.resolve(undefined)
}

/**
 * Given an SCSS exported value, this function parses its content, into an object that can be consumed by JS.
 * @param exported The raw string received when using a default import on a .scss file.
 */
export function parseScssVariabiles(
  exported: string
): Record<DeviceTypeMediaQueryNames, string> {
  const almostAnObject = exported.replace(/:export/, '')

  const result = {} as Record<string, string>
  almostAnObject.split(/\n|;/).forEach((row) => {
    const matches = row.match(
      /(?:\b)*([a-zA-Z]*):(?: )*"([a-zA-Z0-9 ():-]*)"(?:;){0,1}/
    )

    if (!matches) {
      return
    }
    const [_matchedString, key, value] = matches

    result[key] = value
  })

  return result
}

export function isCapoB2E(customData: string): boolean {
  try {
    return JSON.parse(customData || '{}')?.stock_type === 'OUT_STORE'
  } catch {
    return false
  }
}

/**
 * Returns progressive numeric ID.
 */
function* idGenerator() {
  let index = 1

  while (true) {
    yield index++
  }
}

const generator = idGenerator()

export function getNewId() {
  return generator.next().value
}

/**
 * Prints a PDF file on iOS devices
 * @param {string} pdfBase64  the input PDF file encoded in Base64 format
 * @param {string} fileName the output filename
 */
export async function printPdfIos(pdfBase64: string, fileName: string) {
  const check = await Filesystem.checkPermissions()
  if (check?.publicStorage !== 'granted') {
    await Filesystem.requestPermissions()
  }
  const fileResult = await Filesystem.writeFile({
    data: pdfBase64,
    directory: Directory.Documents,
    path: fileName,
  })
  await FileOpener.open({
    filePath: fileResult.uri,
    contentType: 'application/pdf',
  })
}

/**
 * Converts a file in blob format to Base64
 * @param blob the input blob to convert
 * @returns a promise which if resolved returns the converted file
 */
export function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader?.result?.split(',')[1])
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}

/**
 * Prints a pdf file, if the device used is an iOs device executes
 *  native print function, otherwise the file is rendered in a browser window
 * @param fileToPrint the input file to print
 */
export async function printFile(fileToPrint: any) {
  if (fileToPrint) {
    const fileName = fileToPrint?.file_name
    const fileContentBase64 = fileToPrint?.pdf_content
    if (fileName && fileContentBase64) {
      const src = 'data:application/pdf;base64,' + fileContentBase64
      if (isNativeMobileApp()) {
        await printPdfIos(fileContentBase64, fileName)
      } else {
        const w = window.open()
        w?.document.write(
          `<html><title>${fileName}</title><body style="border:0; margin:0;"><iframe style="border:0; margin:0;" width='100%' height='100%' src="${src}"></iframe></body></html>`
        )
      }
    } else {
      await store.dispatch(
        NotificationsActions.NOTIFY_ERROR,
        i18n.global.t('pos_common.generic_error')
      )
    }
  } else {
    await store.dispatch(
      NotificationsActions.NOTIFY_ERROR,
      i18n.global.t('pos_common.generic_error')
    )
  }
}

export async function getNewTransactionId() {
  const response = await salesApi.apiV1SalesNewIdtransazioneGet()
  return response.data.data?.attributes?.id_transazione?.toString() || ''
}

export function getContextColor(
  type: string | Array<string>
): string | Array<string> {
  const context: PageContexts =
    store.getters[ContextPageGetters.GET_CONTEXT_PAGE]
  if (context && type) {
    if (Array.isArray(type)) {
      return type.map((t) => `context-${t}-${context.toLowerCase()}`)
    }
    if (type === 'context') {
      return `context-${context.toLowerCase()}`
    }

    return `context-${type}-${context.toLowerCase()}`
  }
  return ''
}

/**
 * Handle navigation by choosing whether to use new page link or old site query string
 * @param {GetMenuData} section
 * @param lowerCasePageContext An optional string representing current page context
 * @returns {Promise<void | NavigationFailure | undefined>}
 */
export async function checkLinkClick(
  section: GetMenuData,
  lowerCasePageContext: string | undefined
): Promise<void | NavigationFailure | undefined> {
  const { link, qs } = section.attributes || {}

  if (link) {
    const pageLink = link.replace('.', '/')
    return router.push(pageLink)
  }

  if (!isSupportedPage(section)) {
    modalController.open({
      type: ModalTypes.WARNING,
      title: i18n.global.t('pos_common.attention'),
      message: i18n.global.t('pos_common.unsupportedPage'),
      confirmActionButton: false,
    })
    return Promise.resolve()
  }

  const queryString = qs?.replace(/^\?/, '') || ''
  let pageContext: PageContexts = PageContexts.GENERIC

  if (!lowerCasePageContext) {
    // If we have not received data about current page context, we have to find it out by ourself.
    const menuItems = store.getters[
      MenuGetters.GET_FLATTENED_ITEMS_WITH_CONTEXT
    ] as Record<string, GetMenuAttributesSubmenu[]>

    const submenuTree = Object.entries(menuItems).find(([_context, items]) =>
      items.some((e) => e.id === section.id)
    )

    if (submenuTree?.[0]) {
      lowerCasePageContext = submenuTree[0].toLowerCase()
    }
  }

  // At this point we have a string, `lowerCasePageContext`, representing the context for this page.
  // Hence, we can search it and get the corresponding key from the enum.
  const matchingPageCtx = Object.entries(PageContexts).find(
    ([_key, value]) => value.toLowerCase() === lowerCasePageContext
  )

  pageContext = (matchingPageCtx?.[0] as PageContexts) || PageContexts.GENERIC

  // If the page is an external page, we open it in a new tab/popup.
  if (isExternalPage(section, pageContext)) {
    // Show a loading spinner as soon as possible, giving user some feedback.
    LoaderController.show({ section: pageContext })

    const pointedPageURL = await getPointedIframePageURL(queryString)

    if (pointedPageURL) {
      try {
        await Browser.open({
          url: pointedPageURL,
          presentationStyle: 'fullscreen',
          windowName: 'externalPage',
        })
      } finally {
        // At this point we can hide the loading spinner. If we are in the iOS app, we are still displaying
        // the browser window on top of the app itself, but this is not a problem: once user closes the
        // Safari WebView, we are ready to go.
        LoaderController.hide()
      }
    }
  } else {
    // Otherwise we load it in current FE using an iframe.
    const pageLink = `${Routes.PREVIOUS_UI_PAGES}?${queryString}`
    return router.push(pageLink)
  }
}

export function isSupportedPage(menuItem: GetMenuData) {
  // If we have a link and we are handling a new page that is not an iframe on older one, we can proceed.
  if (
    !!menuItem.attributes?.link &&
    menuItem.attributes.link !== Routes.PREVIOUS_UI_PAGES
  ) {
    return true
  }

  // Otherwise we have to check whether current screen is large enough.
  // This means that we will show the older pages only on iPad Pro 11 and larger devices (such as desktops).
  return (
    window.matchMedia(SCSS_MIXINS.queryIpadProLandscape).matches ||
    window.matchMedia(SCSS_MIXINS.queryDesktop).matches
  )
}

export const tradLanguages = [
  { trad: 'codici_lingue.ITAL', value: 'it' },
  { trad: 'codici_lingue.INGL', value: 'en' },
  { trad: 'codici_lingue.FRAN', value: 'fr' },
  { trad: 'codici_lingue.SPAG', value: 'es' },
  { trad: 'codici_lingue.PORT', value: 'pt' },
  { trad: 'codici_lingue.TEDE', value: 'de' },
  { trad: 'codici_lingue.JAPA', value: 'jp' },
  { trad: 'codici_lingue.CHIN', value: 'zh' },
  { trad: 'codici_lingue.OLAN', value: 'nl' },
  { trad: 'codici_lingue.DANE', value: 'da' },
  { trad: 'codici_lingue.UNGH', value: 'hu' },
]

export function getTranslationFromPoswebLanguages(language: string): string {
  return (
    tradLanguages.find(
      (el) =>
        el.value === LanguagesPosweb[language as keyof typeof LanguagesPosweb]
    )?.trad || ''
  )
}

export function getPoswebLanguages(language: string): string {
  return LanguagesIso[language as keyof typeof LanguagesIso]
}

/**
 * Given the query string of a page, this function returns the URL of the included iframe that should be opened in an
 * external tab/popup. This is the case with those pages that in the previous UI are iframes containing another iframe
 * pointing, for example, to a BMS. This function hence extract the URL of the included iframe and returns it.
 * Returned value is a string representing the URL on the inner iframe. It can be `undefined` if no iframes are found.
 * @param menuItemQueryString Query string of the clicked menu item
 */
async function getPointedIframePageURL(menuItemQueryString: string) {
  const poswebInstance =
    store.getters[ConfigGetters.GET_SELECTED_CASH_URL] ||
    (await getPoswebOrigin())

  const searchParams = new URLSearchParams(menuItemQueryString)
  searchParams.append('hide_menu', '1')

  // If for whatever reason the backend instance origin is not the same that is serving the frontend application,
  // we have to append current SID, otherwise we would appear as unauthenticated users for the iframe origin.
  // The reason for this is that we cannot set cookies for third party origins.
  if (poswebInstance !== window.location.origin) {
    searchParams.append('sid', store.getters[AuthGetters.GET_SID])
  }

  const pageUrl = `${poswebInstance}/?${searchParams.toString()}`

  const response = await fetch(pageUrl)
  const parser = new DOMParser()
  const page = parser.parseFromString(await response.text(), 'text/html')

  const iframe = page.querySelector('iframe')
  return iframe?.src
}

/**
 * Returns true wheter specified page should be displayed as an external tab/popup.
 * False otherwise.
 * @param link Clicked menu item details
 * @param pageContext Current page context
 */
export function isExternalPage(
  link: GetMenuData,
  pageContext: PageContexts | string
) {
  if (
    link.attributes?.link?.startsWith(Routes.PREVIOUS_UI_PAGES) ||
    link.attributes?.qs?.startsWith('?')
  ) {
    if (pageContext.toLowerCase() === PageContexts.STOCK.toLowerCase()) {
      return true
    }

    if (link.id && ADDITIONAL_BMS_PAGES.includes(link.id)) {
      return true
    }
  }

  return false
}

interface paymentsSignMatrixValueD {
  negativeValue: boolean
}
interface paymentsSignMatrixD {
  [k: string]: paymentsSignMatrixValueD
}
export const paymentsSignMatrix: paymentsSignMatrixD = {
  [PaymentsModules.B2EREFUNDS]: {
    negativeValue: true,
  },
  [PaymentsModules.RETURNS]: {
    negativeValue: true,
  },
  [PaymentsModules.SUSPENDED_CREDIT]: {
    negativeValue: false,
  },
}
export function checkSignPayments(
  module: PaymentsModules
): paymentsSignMatrixValueD {
  return paymentsSignMatrix[module] ?? {}
}

export async function addUserToLocal(user: GetConsumerAttributes | undefined) {
  try {
    const PkConsumerAddToLocalData = {
      data: {
        type: 'consumer_add_to_local',
        attributes: {
          pk_consumer: user?.pk_consumer,
        },
      },
    }

    await consumerDataApi.apiV1ConsumersPkConsumerAddToLocalPost(
      user?.pk_consumer,
      PkConsumerAddToLocalData
    )
  } catch (error: any) {
    let errorMessage = i18n.global.t('pos_common.generic_error')

    if (error !== '') {
      errorMessage = error
    }

    store.dispatch(NotificationsActions.NOTIFY_ERROR, errorMessage, {
      root: true,
    })
  }
}

export const normalizeStringIdentifier = (name: string): string => {
  const val = name ? name : '' // is it necessary?
  return val.toLowerCase().replaceAll(/ /gi, '-')
}

export const getFrontendApplicationType = (): string => {
  return checkDevice() === 'pc' ? 'ABSTRID' : 'ABSTRIDAPP'
}
