import { PageContexts } from '@/addons/enums'
import i18n from '@/addons/i18n'
import {
  BarcodeScanningMode,
  BarcodeValidatorFunction,
} from '@/interfaces/generics'
import router from '@/router'
import { App, createApp } from 'vue'
import BarcodeScanner from './views/barcode-scanner.vue'
import store from '@/store'

const APP_CONTENT_SELCTOR = '[data-app-content=""]'

abstract class Controller {
  private static mountEl?: HTMLElement
  private static instance?: App<Element>
  private static routerRegistration?: () => void
  static isOpen() {
    return typeof Controller.instance !== 'undefined'
  }

  static scan(
    context?: PageContexts,
    validator?: BarcodeValidatorFunction,
    mode?: 'single',
    cashier?: string,
  ): Promise<string>

  static scan(
    context?: PageContexts,
    validator?: BarcodeValidatorFunction,
    mode?: 'multiple',
    cashier?: string,
    onBarcode?: (barcode: string) => Promise<void>,
  ): Promise<string[]>

  static scan(
    context?: PageContexts,
    validator?: BarcodeValidatorFunction,
    mode: BarcodeScanningMode = 'single',
    cashier?: string,
    onBarcode?: (barcode: string) => Promise<void>,
  ): Promise<string | string[]> {
    return new Promise((resolve) => {
      Controller.mountEl = document.createElement('div')
      const container = document.querySelector(
        APP_CONTENT_SELCTOR,
      ) as HTMLElement
      // Add the newly created HTML element to the "ion-content" item.
      container.appendChild(Controller.mountEl)
      // Create a new Vue application to handle the barcode scanning process.
      Controller.instance = createApp(BarcodeScanner, {
        // Specify some properties
        context: context,
        container: container,
        validator: validator,
        mode: mode,
        cashier: cashier,

        // Make sure to bind an event listener triggered when a barcode is read. This can be used
        // only when the `mode` parameter is set to `multiple`. This way, we can perform actions
        // while user is still reading barcodes. We do not do this when `mode` is set to `single`
        // as it would be the same thing as awaiting the resulting promise.
        onBarcode,

        // And listen for events
        onResults(barcodes: string[]) {
          // Since we got a result, we can unmount this component and remove its' HTML element
          Controller.unmountComponent()
          // We can now resolve the promise and return the barcode we just read.
          if (mode === 'single') {
            // If we where requested a single barcode, we return the first one we read.
            return resolve(barcodes[0])
          }

          return resolve(barcodes)
        },
      })

      // At this point we can mount the new Vue application.
      Controller.instance.use(i18n)
      Controller.instance.use(store)
      Controller.instance.mount(Controller.mountEl)

      // When user tries to change path, we have to unmount this component.
      Controller.routerRegistration = router.beforeEach(() => {
        Controller.unmountComponent()
      })
    })
  }

  static unmountComponent() {
    // If we are still listening for route changes, we can remove the listener.
    Controller.routerRegistration?.()

    // Unmount the Vue application.
    Controller.instance?.unmount()

    // Remove the HTML element that hold the application.
    Controller.mountEl?.parentElement?.removeChild(Controller.mountEl)

    // Technically, this manual state reset is not strictly required, but this allows us to have a consistent internal state.
    Controller.instance = undefined
    Controller.mountEl = undefined
    Controller.routerRegistration = undefined
  }
}

export type { BarcodeValidatorFunction }
export default Controller
