import { DeliveryDataD } from '@/components/drawer/extra-services/delivery-section/delivery-section.d'

export interface State {
  statusValidateCheck: {
    success: boolean
    warning: boolean
    error: boolean
  }
  selectedAddress: DeliveryDataD
  statusValidateCheckConsumer: boolean
  statusValidateCheckStore: boolean
  statusValidateCheckAddress: true
}
export const initState = (): State => ({
  statusValidateCheck: {
    success: false,
    warning: false,
    error: false,
  },
  selectedAddress: {} as DeliveryDataD,
  statusValidateCheckConsumer: false,
  statusValidateCheckStore: false,
  statusValidateCheckAddress: true,
})
