import { MutationTree } from 'vuex'
import { initState, State } from './_state'
import {
  GetConsumerAttributes,
  GetConsumerAttributesUltimeModifiche,
  GetCountriesAnonymous,
} from '@/api'
import { AnonymousConsumer } from '@/interfaces/anonymous-consumer'
import { nextTick } from 'vue'
import { selectedConsumerStatus, Statistics } from './index.d'

export enum MutationNames {
  STORE_CONSUMER = 'store-consumes',
  STORE_ANONYMOUS = 'store-anonymous',
  RESET_STATE = 'reset-state',
  STORE_LAST_CHANGE_CONSUMER = 'store-last-change-consumer',
  SET_CURRENT_CONSUMER = 'set-current-consumer',
  SET_DATA_VISIBILITY = 'set-data-visibility',
  RESET_DATA_VISIBILITY = 'reset-data-visibility',
  SET_SELECTED_CONSUMER_STATUS = 'set-selected-consumer-status',
  SET_IS_JUST_CREATED = 'set-is-just-created',
  STORE_NATIONS_ANONYMOUS = 'store-nations-anonymous',
}

export type Mutations = {
  [MutationNames.STORE_CONSUMER](
    state: State,
    consumers: GetConsumerAttributes[]
  ): Promise<void>
  [MutationNames.STORE_ANONYMOUS](
    state: State,
    anonymousCustomers: AnonymousConsumer[]
  ): void
  [MutationNames.RESET_STATE](state: State): void
  [MutationNames.STORE_LAST_CHANGE_CONSUMER](
    state: State,
    lastChangeConsumer: GetConsumerAttributesUltimeModifiche[]
  ): void
  [MutationNames.SET_DATA_VISIBILITY](state: State, payload: Statistics): void
  [MutationNames.RESET_DATA_VISIBILITY](state: State): void
  [MutationNames.SET_SELECTED_CONSUMER_STATUS](
    state: State,
    payload: selectedConsumerStatus
  ): void
  [MutationNames.SET_IS_JUST_CREATED](state: State, payload: boolean): void
  [MutationNames.STORE_NATIONS_ANONYMOUS](
    state: State,
    nationsAnonymous: GetCountriesAnonymous[]
  ): void
}

export const mutations: MutationTree<State> & Mutations = {
  async [MutationNames.STORE_CONSUMER](
    state: State,
    consumers: GetConsumerAttributes[]
  ) {
    state.consumers = []
    await nextTick()
    state.consumers = consumers
  },
  [MutationNames.STORE_ANONYMOUS](
    state: State,
    anonymousCustomers: AnonymousConsumer[]
  ) {
    state.anonymousConsumer = anonymousCustomers
  },
  [MutationNames.RESET_STATE](state: State) {
    state = Object.assign({}, initState())
  },
  [MutationNames.STORE_LAST_CHANGE_CONSUMER](
    state: State,
    lastChangeConsumer: GetConsumerAttributesUltimeModifiche[]
  ): void {
    state.lastChangeConsumer = lastChangeConsumer
  },
  [MutationNames.SET_CURRENT_CONSUMER](
    state: State,
    payload: GetConsumerAttributes
  ): void {
    state.currentConsumer = Object.assign({}, payload)
  },
  [MutationNames.SET_DATA_VISIBILITY](state: State, payload: Statistics): void {
    state.consumerStatistics = payload
  },
  [MutationNames.RESET_DATA_VISIBILITY](state: State): void {
    state.consumerStatistics = undefined
  },
  [MutationNames.SET_SELECTED_CONSUMER_STATUS](
    state: State,
    payload: selectedConsumerStatus
  ) {
    state.selectedConsumerStatus = payload
  },
  [MutationNames.SET_IS_JUST_CREATED](state: State, payload: boolean) {
    state.isJustCreated = payload
  },
  [MutationNames.STORE_NATIONS_ANONYMOUS]: (
    state: State,
    nations: GetCountriesAnonymous[]
  ): void => {
    state.nationsAnonymous = nations
  },
}
