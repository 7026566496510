import { paymentApi, storeManagementApi } from '@/addons/axios'
import {
  ApiV1SalesDataAttributesPagamenti,
  GetAllowedPaymentsAttributes,
  GetCreditCardCircuits1DataAttributes,
  GetCreditCardCircuitsData,
  GetDocumentTypesAttributes,
} from '@/api'
import store, { RootState } from '@/store/index'
import { ActionContext } from 'vuex'
import { SalesGetters, SalesState } from '@/store/sales/sales-store'
import { LaybyStatusType } from '@/addons/enums'

export enum PaymentsType {
  ASSEGNI = 'ASSEGNI',
  CREDITO_SOSPESO = 'CREDITO_SOSPESO',
  CARTA = 'CARTA',
  CONTANTI = 'CONTANTI',
  BUONO_GIFT_CARD = 'BUONO_GIFT_CARD',
  GIFT_CARD_CORPORATE = 'GIFT_CARD_CORPORATE',
  MALL_COUPON = 'MALL_COUPON',
  NON_RISCOSSO = 'NON_RISCOSSO',
  ALIPAY = 'ALIPAY',
  GENERIC = 'GENERIC',
}

export enum CostiExtraType {
  GIFT_CARD = 'GIFT_CARD',
}

export const paymentLabel: { [key in PaymentsType]: string } = {
  [PaymentsType.ASSEGNI]: 'Assegni',
  [PaymentsType.CREDITO_SOSPESO]: 'Credito sospeso',
  [PaymentsType.CARTA]: 'Carta',
  [PaymentsType.CONTANTI]: 'Contanti',
  [PaymentsType.BUONO_GIFT_CARD]: 'Buono gift card',
  [PaymentsType.GIFT_CARD_CORPORATE]: 'Gift card',
  [PaymentsType.MALL_COUPON]: 'Mall coupon',
  [PaymentsType.NON_RISCOSSO]: 'Non riscosso',
  [PaymentsType.ALIPAY]: 'Alipay',
  [PaymentsType.GENERIC]: 'Generico',
}

export interface PaymentsValidators {
  [key: string]: boolean | null
}

export interface PaymentsState {
  types: GetAllowedPaymentsAttributes[]
  documents: GetCreditCardCircuits1DataAttributes[]
  ccCounters: { [k: string]: number }
  payments_validator: PaymentsValidators
  selectedPayments: ApiV1SalesDataAttributesPagamenti[]
  laybyTypes: GetAllowedPaymentsAttributes[]
  progressivo_pagamenti: number
}

enum Mutations {
  SET_TYPES = 'set-types',
  CLEAR_TYPES = 'clear-types',
  CLEAR_DOCUMENTS = 'clear-documents',
  SET_DOCUMENTS = 'set-documents',
  INCREMENT_COUNTER = 'increment-counter',
  STORE_NEW_COUNTER = 'store-new-counter',
  CLEAR_COUNTER = 'clear_counter',
  RESET_STATE = 'reset-state',
  DELETE_PAYMENT = 'delete-payment',
  STORE_VALIDATION_PAGAMENTI = 'store-validation-pagamenti',
  CLEAR_VALIDATION_PAGAMENTI = 'clear-validation-pagamenti',
  ADD_PAYMENT = 'add-payment',
  REPLACE_PAYMENT = 'replace-payment',
  UPDATE_PAYMENTS = 'update-payments',
  UPDATE_DATI_OPERAZIONE = 'update-dati-operazione',
  SET_LAYBY_PAYMENTS_TYPES = 'set-layby-payments-types',
  RESET_SELECTED_PAYMENTS = 'reset-selected-payments',
  STORE_PROGRESSIVO_PAGAMENTI = 'payment-progressivo-pagamenti-store',
  CLEAR_PROGRESSIVO_PAGAMENTI = 'payment-progressivo-pagamenti-clear',
}

enum Actions {
  GET_PAYMENTS_TYPES = 'get-payments-type',
  GET_DOCUMENTS_TYPES = 'get-documents-type',
  INCREMENT_COUNTER = 'increment-counter',
  RESET_STATE = 'reset-state',
  CLEAR_COUNTER = 'clear-counter',
  ADD_PAYMENT = 'add-payment',
  REPLACE_PAYMENT = 'replace-payment',
  UPDATE_SELECTED_PAYMENT = 'update-selected-payment',
  UPDATE_PAYMENT_VALIDATION = 'update-payment-validation',
  REMOVE_SELECTED_PAYMENT = 'remove-selected-payment',
  GET_LAYBY_PAYMENTS_TYPES = 'get-layby-payments-types',
  SET_DATI_OPERAZIONE = 'set-dati-operazione',
  REMOVE_ALL_SELECTED_PAYMENT = 'remove-all-selected-payment',
  RESET_SELECTED_PAYMENTS = 'reset-selected-payments',
}

enum Getters {
  GET_PAYMENTS_TYPES = 'get-payments-types',
  GET_DOCUMENTS_TYPES = 'get-documents-types',
  GET_PAYMENTS_VALIDATION = 'get-payments-validation',
  GET_SELECTED_PAYMENTS = 'get-selected-payments',
  GET_TOTAL_SELECTED_PRICE = 'get-totale-selected-price',
  GET_HAS_ALREADY_CASH_PAYMENT = 'get-has-already-cash-payment',
  GET_LAYBY_PAYMENTS_TYPES = 'get-layby-payments-types',
  GET_PAYMENTS_VALIDATION_ALL = 'get-payments-validation-all',
  GET_PROGRESSIVO_PAGAMENTI = 'get-progressivo-pagamenti',
  GET_CCCOUNTERS = 'get-cccounter',
}

export const PaymentsMutations = {
  SET_TYPES: `payments/${Mutations.SET_TYPES}`,
  CLEAR_TYPES: `payments/${Mutations.CLEAR_TYPES}`,
  SET_DOCUMENTS: `payments/${Mutations.SET_DOCUMENTS}`,
  CLEAR_DOCUMENTS: `payments/${Mutations.CLEAR_DOCUMENTS}`,
  INCREMENT_COUNTER: `payments/${Mutations.INCREMENT_COUNTER}`,
  STORE_NEW_COUNTER: `payments/${Mutations.STORE_NEW_COUNTER}`,
  CLEAR_COUNTER: `payments/${Mutations.CLEAR_COUNTER}`,
  RESET_STATE: `payments/${Mutations.RESET_STATE}`,
  DELETE_PAYMENT: `payments/${Mutations.DELETE_PAYMENT}`,
  STORE_VALIDATION_PAGAMENTI: `payments/${Mutations.STORE_VALIDATION_PAGAMENTI}`,
  CLEAR_VALIDATION_PAGAMENTI: `payments/${Mutations.CLEAR_VALIDATION_PAGAMENTI}`,
  SET_LAYBY_PAYMENTS_TYPES: `payments/${Mutations.SET_LAYBY_PAYMENTS_TYPES}`,
}

export const PaymentsActions = {
  GET_PAYMENTS_TYPES: `payments/${Actions.GET_PAYMENTS_TYPES}`,
  GET_DOCUMENTS_TYPES: `payments/${Actions.GET_DOCUMENTS_TYPES}`,
  INCREMENT_COUNTER: `payments/${Actions.INCREMENT_COUNTER}`,
  CLEAR_COUNTER: `payments/${Actions.CLEAR_COUNTER}`,
  RESET_STATE: `payments/${Actions.RESET_STATE}`,
  ADD_PAYMENT: `payments/${Actions.ADD_PAYMENT}`,
  REPLACE_PAYMENT: `payments/${Actions.REPLACE_PAYMENT}`,
  UPDATE_SELECTED_PAYMENT: `payments/${Actions.UPDATE_SELECTED_PAYMENT}`,
  UPDATE_PAYMENT_VALIDATION: `payments/${Actions.UPDATE_PAYMENT_VALIDATION}`,
  REMOVE_SELECTED_PAYMENT: `payments/${Actions.REMOVE_SELECTED_PAYMENT}`,
  GET_LAYBY_PAYMENTS_TYPES: `payments/${Actions.GET_LAYBY_PAYMENTS_TYPES}`,
  SET_DATI_OPERAZIONE: `payments/${Actions.SET_DATI_OPERAZIONE}`,
  REMOVE_ALL_SELECTED_PAYMENT: `payments/${Actions.REMOVE_ALL_SELECTED_PAYMENT}`,
  RESET_SELECTED_PAYMENTS: `payments/${Actions.RESET_SELECTED_PAYMENTS}`,
}

export const PaymentsGetters = {
  GET_PAYMENTS_TYPES: `payments/${Getters.GET_PAYMENTS_TYPES}`,
  GET_DOCUMENTS_TYPES: `payments/${Getters.GET_DOCUMENTS_TYPES}`,
  GET_PAYMENTS_VALIDATION: `payments/${Getters.GET_PAYMENTS_VALIDATION}`,
  GET_SELECTED_PAYMENTS: `payments/${Getters.GET_SELECTED_PAYMENTS}`,
  GET_TOTAL_SELECTED_PRICE: `payments/${Getters.GET_TOTAL_SELECTED_PRICE}`,
  GET_HAS_ALREADY_CASH_PAYMENT: `payments/${Getters.GET_HAS_ALREADY_CASH_PAYMENT}`,
  GET_LAYBY_PAYMENTS_TYPES: `payments/${Getters.GET_LAYBY_PAYMENTS_TYPES}`,
  GET_PAYMENTS_VALIDATION_ALL: `payments/${Getters.GET_PAYMENTS_VALIDATION_ALL}`,
  GET_PROGRESSIVO_PAGAMENTI: `payments/${Getters.GET_PROGRESSIVO_PAGAMENTI}`,
  GET_CCCOUNTERS: `payments/${Getters.GET_CCCOUNTERS}`,
}

const initState = (): PaymentsState => ({
  types: [],
  documents: [],
  ccCounters: {},
  payments_validator: {},
  selectedPayments: [],
  laybyTypes: [],
  progressivo_pagamenti: 0,
})

export default {
  namespaced: true,
  state: initState,
  mutations: {
    [Mutations.SET_TYPES](
      state: PaymentsState,
      types: GetAllowedPaymentsAttributes[]
    ): void {
      state.types = types
    },
    [Mutations.STORE_VALIDATION_PAGAMENTI]: (
      state: PaymentsState,
      validation: { [key: string]: boolean }
    ): void => {
      state.payments_validator = validation
    },
    [Mutations.CLEAR_VALIDATION_PAGAMENTI]: (state: PaymentsState): void => {
      state.payments_validator = {}
    },
    [Mutations.CLEAR_TYPES](state: PaymentsState): void {
      state.types = []
    },
    [Mutations.SET_DOCUMENTS](
      state: PaymentsState,
      types: GetCreditCardCircuits1DataAttributes[]
    ): void {
      state.documents = types
    },
    [Mutations.CLEAR_DOCUMENTS](state: PaymentsState): void {
      state.documents = []
    },
    [Mutations.INCREMENT_COUNTER](
      state: PaymentsState,
      progressivo: string
    ): void {
      state.ccCounters[progressivo]++
    },
    [Mutations.STORE_NEW_COUNTER](
      state: PaymentsState,
      progressivo: string
    ): void {
      state.ccCounters[progressivo] = 1
    },
    [Mutations.CLEAR_COUNTER](state: PaymentsState, progressivo: string): void {
      delete state.ccCounters[progressivo]
    },
    [Mutations.RESET_STATE](state: PaymentsState): void {
      Object.assign(state, initState())
    },
    [Mutations.ADD_PAYMENT]: (
      state: PaymentsState,
      payment: ApiV1SalesDataAttributesPagamenti
    ) => {
      const selectedProgressivo = state.selectedPayments.map(
        (payment) => payment.progressivo
      )
      if (!selectedProgressivo.includes(payment.progressivo)) {
        state.selectedPayments.push(payment)
      }
    },
    [Mutations.REPLACE_PAYMENT]: (
      state: PaymentsState,
      payment: ApiV1SalesDataAttributesPagamenti
    ) => {
      const indexToFound = state.selectedPayments.findIndex(
        (el) => el.progressivo === payment.progressivo
      )
      if (indexToFound > -1) {
        state.selectedPayments[indexToFound] = payment
      }
    },
    [Mutations.UPDATE_PAYMENTS]: (
      state: PaymentsState,
      payload: ApiV1SalesDataAttributesPagamenti[]
    ) => {
      state.selectedPayments = payload
    },
    [Mutations.SET_LAYBY_PAYMENTS_TYPES](
      state: PaymentsState,
      data: GetAllowedPaymentsAttributes[]
    ): void {
      state.laybyTypes = data
    },
    [Mutations.UPDATE_DATI_OPERAZIONE](
      state: PaymentsState,
      payload: {
        progressivo: number
        dati_operazione: string
      }
    ): void {
      const payment_to_update = state.selectedPayments.find(
        (payment) => payment.progressivo === payload.progressivo
      )

      Object.assign(payment_to_update ?? {}, {
        dati_operazione: payload.dati_operazione,
      })
    },
    [Mutations.RESET_SELECTED_PAYMENTS](state: PaymentsState): void {
      state.selectedPayments = []
    },
    [Mutations.STORE_PROGRESSIVO_PAGAMENTI]: (
      state: SalesState,
      progressivo: number
    ): void => {
      state.progressivo_pagamenti = progressivo
    },
    [Mutations.CLEAR_PROGRESSIVO_PAGAMENTI]: (state: SalesState): void => {
      state.progressivo_pagamenti = 0
    },
  },
  getters: {
    [Getters.GET_HAS_ALREADY_CASH_PAYMENT](state: PaymentsState): boolean {
      return !!state.selectedPayments.find(
        (payment) => payment.cod_operazione === 'CONTANTI'
      )
    },
    [Getters.GET_PAYMENTS_TYPES](
      state: PaymentsState
    ): GetAllowedPaymentsAttributes[] {
      return state.types
    },
    [Getters.GET_TOTAL_SELECTED_PRICE](state: PaymentsState): number {
      return state.selectedPayments.reduce(
        (total: number, payment: ApiV1SalesDataAttributesPagamenti) =>
          total + (payment.importo_finale ?? 0),
        0
      )
    },
    [Getters.GET_DOCUMENTS_TYPES](
      state: PaymentsState
    ): GetDocumentTypesAttributes[] {
      return state.documents
    },
    [Getters.GET_PAYMENTS_VALIDATION]: (
      state: PaymentsState
    ): boolean | null => {
      if (!Object.keys(state.payments_validator).length) {
        return false
      }
      return Object.keys(state.payments_validator).reduce(
        (v: boolean | null, k: string) => v && state.payments_validator[k],
        true
      )
    },
    [Getters.GET_PAYMENTS_VALIDATION_ALL]: (
      state: PaymentsState
    ): PaymentsValidators | null => state.payments_validator,
    [Getters.GET_SELECTED_PAYMENTS]: (state: PaymentsState) => {
      return state.selectedPayments
    },

    [Getters.GET_LAYBY_PAYMENTS_TYPES]: (state: PaymentsState) => {
      return state.laybyTypes
    },
    [Getters.GET_PROGRESSIVO_PAGAMENTI]: (state: PaymentsState): number =>
      state.progressivo_pagamenti,
    [Getters.GET_CCCOUNTERS]: (state: PaymentsState): { [k: string]: number } =>
      state.ccCounters,
  },
  actions: {
    [Actions.REMOVE_SELECTED_PAYMENT]: (
      context: ActionContext<PaymentsState, RootState>,
      progressivo: number
    ) => {
      const selectedPaymentsFiltered = context.state.selectedPayments.filter(
        (payment) => payment.progressivo !== progressivo
      )
      context.commit(Mutations.UPDATE_PAYMENTS, selectedPaymentsFiltered)
    },
    [Actions.REMOVE_ALL_SELECTED_PAYMENT]: (
      context: ActionContext<PaymentsState, RootState>
    ) => {
      context.commit(Mutations.UPDATE_PAYMENTS, [])
    },
    [Actions.UPDATE_PAYMENT_VALIDATION]: (
      context: ActionContext<PaymentsState, RootState>,
      payload: { progressivo: string; value: boolean }
    ) => {
      context.commit(Mutations.STORE_VALIDATION_PAGAMENTI, {
        ...context.state.payments_validator,
        [payload.progressivo]: payload.value,
      })
    },
    [Actions.UPDATE_SELECTED_PAYMENT]: (
      context: ActionContext<PaymentsState, RootState>,
      payload: {
        cod_operazione: string
        formData: string
        importo: number
        progressivo: number
        id_transazione: number
        importo_da_pagare: number
      }
    ): void => {
      const updatedSelectedPayment = context.state.selectedPayments?.map((p) =>
        p.progressivo === payload.progressivo
          ? {
              ...p,
              dati_operazione:
                payload.formData === '' ? '{}' : payload.formData,
              importo_finale: payload.importo,
              importo_iniziale: payload.importo ?? payload.importo_da_pagare,
              id_transazione: payload.id_transazione,
            }
          : p
      )
      if (context.state.selectedPayments) {
        context.commit(Mutations.UPDATE_PAYMENTS, updatedSelectedPayment)
        context.commit(Mutations.STORE_VALIDATION_PAGAMENTI, {
          ...context.state.payments_validator,
          [payload.cod_operazione]: true,
        })
      }
    },
    [Actions.GET_PAYMENTS_TYPES]: async (
      context: ActionContext<PaymentsState, RootState>,
      filterType?: 'sale' | 'return' | 'layby' | 'suspended_sale' | undefined
    ) => {
      const response = await storeManagementApi.apiV1SalesPaymentsGet(
        filterType
      )
      context.commit(
        Mutations.SET_TYPES,
        response.data.data?.map((type) => type.attributes)
      )
    },
    [Actions.GET_LAYBY_PAYMENTS_TYPES]: async (
      context: ActionContext<PaymentsState, RootState>
    ) => {
      const response = await storeManagementApi.apiV1SalesPaymentsGet('layby')
      let filterdPaymentsType
      if (
        store.getters[SalesGetters.GET_LAYBY_STATUS] ===
        LaybyStatusType.RECAP_DELETE
      ) {
        filterdPaymentsType = response.data.data?.filter(
          (el) =>
            el.attributes?.codice_pagamento === PaymentsType.CONTANTI ||
            el.attributes?.codice_pagamento === PaymentsType.CARTA
        )
      } else {
        filterdPaymentsType = response.data.data
      }
      context.commit(
        Mutations.SET_LAYBY_PAYMENTS_TYPES,
        filterdPaymentsType?.map((type) => type.attributes)
      )
    },
    [Actions.GET_DOCUMENTS_TYPES]: async (
      context: ActionContext<PaymentsState, RootState>
    ) => {
      const response = await paymentApi.apiV1PoswebDocumentTypesGet(
        'ASSEGNO_VENDITA'
      )
      const data = response.data.data as GetCreditCardCircuitsData[] | undefined

      const attributes = data?.map(
        (d: GetCreditCardCircuitsData) => d.attributes
      )

      context.commit(Mutations.SET_DOCUMENTS, attributes)
    },
    [Actions.INCREMENT_COUNTER]: (
      context: ActionContext<PaymentsState, RootState>,
      progressivo: string
    ) => {
      if (typeof context.state.ccCounters[progressivo] === 'undefined') {
        context.commit(Mutations.STORE_NEW_COUNTER, progressivo)
      } else {
        context.commit(Mutations.INCREMENT_COUNTER, progressivo)
      }
    },
    [Actions.CLEAR_COUNTER]: (
      context: ActionContext<PaymentsState, RootState>,
      progressivo: string
    ) => {
      context.commit(Mutations.CLEAR_COUNTER, progressivo)
    },
    [Actions.RESET_STATE]: (
      context: ActionContext<PaymentsState, RootState>
    ) => {
      context.commit(Mutations.RESET_STATE)
    },
    [Actions.ADD_PAYMENT]: async (
      context: ActionContext<PaymentsState, RootState>,
      payment: ApiV1SalesDataAttributesPagamenti
    ) => {
      const progressivo_pagamenti = context.state.progressivo_pagamenti + 1
      payment.progressivo = progressivo_pagamenti
      context.commit(
        Mutations.STORE_PROGRESSIVO_PAGAMENTI,
        progressivo_pagamenti
      )
      context.commit(Mutations.ADD_PAYMENT, payment)
      await context.dispatch(Actions.UPDATE_PAYMENT_VALIDATION, {
        progressivo: payment.progressivo,
        value: false,
      })
    },
    [Actions.REPLACE_PAYMENT]: (
      context: ActionContext<PaymentsState, RootState>,
      payment: ApiV1SalesDataAttributesPagamenti
    ) => {
      context.commit(Mutations.REPLACE_PAYMENT, payment)
    },
    [Actions.SET_DATI_OPERAZIONE]: (
      context: ActionContext<PaymentsState, RootState>,
      payload: {
        progressivo: number
        dati_operazione: unknown
      }
    ) => {
      const dati_operazione_stringify = JSON.stringify(payload.dati_operazione)
      context.commit(Mutations.UPDATE_DATI_OPERAZIONE, {
        progressivo: payload.progressivo,
        dati_operazione: dati_operazione_stringify,
      })
    },
    [Actions.RESET_SELECTED_PAYMENTS]: (
      context: ActionContext<PaymentsState, RootState>
    ) => {
      context.commit(Mutations.RESET_SELECTED_PAYMENTS)
    },
  },
}
